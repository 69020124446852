import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import { FaWhatsapp } from 'react-icons/fa';

const Footer = () => {
    return (
        <>
            <footer id="footer">
                <div className="container">
                    <div className="row">
                        <div className="col-md-3">
                            <br />
                            <h2>JEEVAN PATH  <br /> <span> FOUNDATION</span></h2>
                            <div className="footer-about">
                                <p style={{ color: 'black' }}>
                                    सामाजिक बदलाव की दिशा में प्रतिबद्धता: संगठन समाज के कमजोर वर्ग के लिए काम करता है और उन्हें सशक्त बनाने के लिए निरंतर प्रयासरत है।
                                </p>
                            </div>
                        </div>

                        <div className="col-md-3">
                            <div className="useful-link">
                                <h2>Useful Links</h2>

                                <div className="use-links">
                                    <ul>
                                        <li>
                                            <Link to='/'>
                                                <i class="fa fa-angle-left"></i>HOME
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to='/About'>
                                                <i class="fa fa-angle-left"></i>ABOUT
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to='/Gallery'>
                                                <i class="fa fa-angle-left"></i>GALLERY
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to='/Contact'>
                                                <i class="fa fa-angle-left"></i>CONTACT
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-3">
                            <div className="social-links">
                                <h2>Services</h2>

                                <div className="social-icons">
                                    <ul>
                                        <li>
                                            <Link to='/'>
                                                <i class="fa fa-angle-left"></i>पशुधन विकास परिषद
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to='/About'>
                                                <i class="fa fa-angle-left"></i>वेक्टर जनित नियंत्रण कार्यक्रम
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to='/Gallery'>
                                                <i class="fa fa-angle-left"></i>छय रोग नियंत्रण कार्यक्रम
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to='/Contact'>
                                                <i class="fa fa-angle-left"></i>राष्ट्रीय पशु रोग नियंत्रण कार्यक्रम
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-3">
                            <div className="address">
                                <h2>Address</h2>

                                <div className="address-links">
                                    <ul>
                                        <li className="address1">
                                            <i class="fa fa-angle-left"></i> Lucknow UP 
                                        </li>
                                        <li>
                                            <a href="tel:0522 - 3658492">
                                                <i class="fa fa-angle-left"></i>0522 - 3658492
                                            </a>
                                        </li>
                                        <li>
                                            <a href="Tel::+91-9415495322">
                                                <i class="fa fa-angle-left"></i>+91-9415495322
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>

            <section id="copy-right">
                <div className="copy-right-sec">
                    <i class="fa fa-copyright"></i> JEEVAN PATH
                    FOUNDATION  {' '}
                  
                </div>
            </section>





            <div className="icon-bar">
                <a href="#" className="facebook"><i class="fa fa-facebook"></i></a>
                <a href="#" className="instgram "><i class="fa fa-instagram"></i></a>
                <a href="#" className="twitter"><i class="fa fa-twitter"></i></a>
                <a href="#" className="youtube"><i class="fa fa-youtube-play"></i></a>
            </div>


            <a href="https://wa.me/919415495322" target="_blank" rel="noopener noreferrer" className="whatsapp-btn">
                <FaWhatsapp size={40} />
            </a>

        </>
    );
};

export default Footer;
